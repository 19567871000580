import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Helper/Button';
import Loader from '../Helper/Loader';

const ErrorPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <div className="flex flex-col justify-between items-center">
        <p className="w-full text-2xl text-center my-8 text-red-500">
          This name already exists!
        </p>
        <Button
          onClick={() => navigate(-1)}
          title="Back" />
      </div>
    </>
  );
};

export default ErrorPage;