import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import genderData from '../utils/gender.json';
import UseTimeFormate from '../utils/UseTimeFormate';


const PersonalInfo = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { login_time, name } = useRecoilValue(clientDataState);
  const setClientDataState = useSetRecoilState(clientDataState);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { JapaneesTimeFormatter } = UseTimeFormate();


  const onSubmit = data => {
    const time = new Date().toUTCString();

    setClientDataState((prev) => {
      const newValue = JSON.parse(JSON.stringify(prev));
      newValue.personal_information_enter_time = time;
      newValue.name = data.name;
      newValue.gender = data.gender;
      return newValue;
    })

    navigate("/destination-select");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          value={JapaneesTimeFormatter(login_time)}
          title="Login Time"
          disabled
        />

        <Input
          register={register}
          title="Name"
          name="name"
          defaultValue={name || ""}
          errors={errors}
          required={true}
        />

        {errors.name?.type === 'required' && <p role="alert" className="text-red-500">Name is required</p>}

        <p className="text-left text-base font-medium py-5 uppercase">Gender</p>
        <div className="ml-6">
          {
            genderData.map((data) => (
              <Input
                key={data.id}
                type="radio"
                value={data.id}
                register={register}
                title={data.title}
                name="gender" />
            ))
          }
        </div>

        <div className="w-full flex justify-end mt-8">
          <Button title="Next" />
        </div>
      </form>
    </>
  );
};

export default PersonalInfo;