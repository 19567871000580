import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import UseTimeFormate from '../utils/UseTimeFormate';


const EnterAmount = () => {
  const { login_time, personal_information_enter_time, from_to_enter_time, date_time_enter_time, amount } = useRecoilValue(clientDataState);
  const [loading, setLoading] = useState(true);
  const [amountValue, setAmountValue] = useState(amount || 0);
  const setClientDataState = useSetRecoilState(clientDataState);
  const { JapaneesTimeFormatter } = UseTimeFormate();
  const navigate = useNavigate();


  const onNextClick = () => {
    const time = new Date().toUTCString();

    setClientDataState((prev) => {
      const newValue = JSON.parse(JSON.stringify(prev));
      newValue.amount_enter_time = time;
      newValue.amount = amountValue;
      return newValue;
    })

    navigate("/enter-note");
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <form>
        <Input
          value={JapaneesTimeFormatter(login_time)}
          title="Login Time"
          disabled
        />

        <Input
          value={JapaneesTimeFormatter(personal_information_enter_time)}
          title="Personal Information Enter Time"
          disabled
        />

        <Input
          value={JapaneesTimeFormatter(from_to_enter_time)}
          title="From To Enter Time"
          disabled
        />

        <Input
          value={JapaneesTimeFormatter(date_time_enter_time)}
          title="Date Time Enter Time"
          disabled
        />

        <Input
          amountValue={amountValue}
          title="Enter Amount (BDT)"
          preInput='৳'
          setAmountValue={setAmountValue}
          type="amount" />
      </form>

      <div className="w-full flex justify-between mt-8">
        <Button onClick={() => navigate('/date-time-select')} title="Back" />
        <Button onClick={onNextClick} title="Next" />
      </div>
    </>
  );
};

export default EnterAmount;