export const CLIENT_DATA = 'client_data';



// amount
// amount_enter_time
// date_time_enter_time
// from_to_enter_time
// gender
// id
// location_from
// location_to
// login_time
// name
// note
// note_enter_time
// personal_information_enter_time
// reservation_date
// reservation_id
// reservation_time
