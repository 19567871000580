import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginState } from '../store/atoms';

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { isLoggedIn } = useRecoilValue(loginState);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/");
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
            {children}
        </>
    );
};

export default PrivateRoute;