import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import destination from '../utils/destination.json';
import UseTimeFormate from '../utils/UseTimeFormate';

const DestinationSelect = () => {
  const { login_time, personal_information_enter_time, location_to, location_from } = useRecoilValue(clientDataState);
  const [loading, setLoading] = useState(true);
  const [fromCity, setFromCity] = useState(
    destination.filter(d => d.id !== location_to)
  );
  const [toCity, setToCity] = useState(destination.filter(d => d.id !== location_from));
  const { JapaneesTimeFormatter } = UseTimeFormate();
  const setClientDataState = useSetRecoilState(clientDataState);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const navigate = useNavigate();


  const fromCityOnchange = (e) => {
    const id = e.target.value;
    const filterTo = destination.filter(c => c.id !== parseInt(id));
    setToCity(filterTo)
  }

  const toCityOnchange = (e) => {
    const id = e.target.value;
    const filterFrom = destination.filter(c => c.id !== parseInt(id));
    setFromCity(filterFrom)
  }



  const onSubmit = (data) => {
    const time = new Date().toUTCString();


    setClientDataState((prev) => {
      const newValue = JSON.parse(JSON.stringify(prev));
      newValue.from_to_enter_time = time;
      newValue.location_to = data.to;
      newValue.location_from = data.from;
      return newValue;
    })

    navigate("/date-time-select");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <form>
        <Input
          value={JapaneesTimeFormatter(login_time)}
          title="Login Time"
          disabled
        />

        <Input
          value={JapaneesTimeFormatter(personal_information_enter_time)}
          title="Personal Information Enter Time"
          disabled
        />

        <div className="my-2">
          <label
            className="block mb-2 text-base uppercase font-medium text-gray-900 "
          >
            From
          </label>
          <select
            defaultValue={location_from || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            {...register("from", { required: "From Destination is required" })}
            onChange={fromCityOnchange}
            aria-invalid={errors.from ? "true" : "false"}
          >
            <option
              value=""
              disabled></option>
            {
              fromCity.map((data) => (
                <option
                  key={data.id}
                  value={data.id}>{data.ja}</option>
              ))
            }
          </select>
          {errors.from && <p role="alert" className="text-base py-3 text-red-500 ">{errors.from?.message}</p>}
        </div>

        <div className="my-2">
          <label
            htmlFor="countries"
            className="block mb-2 text-base uppercase font-medium text-gray-900 ">
            To
          </label>
          <select
            id="countries"
            defaultValue={location_to || ""}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            {...register("to", { required: "To Destination is required" })}
            aria-invalid={errors.to ? "true" : "false"}
            onChange={toCityOnchange}
          >
            <option
              value=""
              disabled></option>
            {
              toCity.map((data) => (
                <option
                  key={data.id}
                  value={data.id}>
                  {data.ja}
                </option>
              ))
            }
          </select>
          {errors.to && <p role="alert" className="text-base py-3 text-red-500 ">{errors.to?.message}</p>}
        </div>

        <div className="w-full flex justify-between mt-8">
          <Button onClick={() => navigate('/personal-info')} title="Back" />
          <Button onClick={handleSubmit(onSubmit)} type="submit" title="Next" />
        </div>
      </form>
    </>
  );
};

export default DestinationSelect;