import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import UseTimeFormate from '../utils/UseTimeFormate';


const DateTimeSelect = () => {
  const { login_time, personal_information_enter_time, from_to_enter_time } = useRecoilValue(clientDataState);
  const [startTime, setStartTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const { JapaneesTimeFormatter } = UseTimeFormate();
  const [loading, setLoading] = useState(true);
  const setClientDataState = useSetRecoilState(clientDataState);
  const navigate = useNavigate();


  const handleSubmitDateTime = () => {
    const time = new Date().toUTCString();

    setClientDataState((prev) => {
      const newValue = JSON.parse(JSON.stringify(prev));
      newValue.date_time_enter_time = time;
      newValue.reservation_time = startTime.toUTCString();
      newValue.reservation_date = startDate.toUTCString();
      return newValue;
    })

    navigate("/enter-amount");
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )


  return (
    <>
      <Input
        value={JapaneesTimeFormatter(login_time)}
        title="Login Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(personal_information_enter_time)}
        title="Personal Information Enter Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(from_to_enter_time)}
        title="From To Enter Time"
        disabled
      />

      <div className="my-5">
        <h3 className="text-base font-medium uppercase pb-4">Date</h3>
        <DatePicker
          dateFormat="yyyy/MM/dd"
          className="py-2 px-3 w-full rounded"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
      </div>
      <div className="my-5">
        <h3 className="text-base font-medium uppercase pb-4">Time</h3>
        <DatePicker
          selected={startTime}
          onChange={(time) => setStartTime(time)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          className="py-2 px-3 w-full rounded"
          timeCaption="Time"
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
      </div>
      <div className="w-full flex justify-between mt-8">
        <Button onClick={() => navigate('/destination-select')} title="Back" />
        <Button onClick={handleSubmitDateTime} title="Next" />
      </div>
    </>
  );
};

export default DateTimeSelect;