import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const setClientDataState = useSetRecoilState(clientDataState);
  const { reservation_id } = useRecoilValue(clientDataState);

  const restartFunction = () => {
    secureLocalStorage.clear()
    setClientDataState({})
    navigate('/top')
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col justify-between gap-8">
          <p className="text-xl">Thank you!</p>
          <p>Your reservation ID: {reservation_id}</p>
        </div>

        <div className="w-full flex justify-between mt-8">
          <Button onClick={() => navigate('/top')} title="Back" />
          <Button title="Restart" onClick={restartFunction} />
        </div>
      </div>
    </>
  );
};

export default SuccessPage;