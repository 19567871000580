import "react-datepicker/dist/react-datepicker.css";
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  RecoilRoot
} from 'recoil';
import Confirmation from "./Components/Confirmation";
import DateTimeSelect from './Components/DateTimeSelect';
import DestinationSelect from './Components/DestinationSelect';
import EnterAmount from "./Components/EnterAmount";
import EnterNote from "./Components/EnterNote";
import ErrorPage from "./Components/ErrorPage";
import LoginContainer from './Components/LoginContainer';
import PersonalInfo from './Components/PersonalInfo';
import SearchResult from "./Components/SearchResult";
import SuccessPage from "./Components/SuccessPage";
import TopPageContainer from './Components/TopPageContainer';
import PrivateRoute from "./Helper/PrivateRoute";
import HomePage from './Pages/HomePage';




function App() {

  return (
    <RecoilRoot>
      <IntlProvider locale={navigator.language}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route index
                element={<PrivateRoute><LoginContainer /></PrivateRoute>}
              />
              <Route path='/top'
                element={<PrivateRoute><TopPageContainer /></PrivateRoute>}
              />
              <Route path='/personal-info'
                element={<PrivateRoute><PersonalInfo /></PrivateRoute>}
              />
              <Route path='/destination-select'
                element={<PrivateRoute><DestinationSelect /></PrivateRoute>}
              />
              <Route path='/date-time-select'
                element={<PrivateRoute><DateTimeSelect /></PrivateRoute>}
              />
              <Route path='/enter-amount'
                element={<PrivateRoute><EnterAmount /></PrivateRoute>}
              />
              <Route path='/enter-note'
                element={<PrivateRoute><EnterNote /></PrivateRoute>}
              />
              <Route path='/final-confirm'
                element={<PrivateRoute><Confirmation /></PrivateRoute>}
              />
              <Route path='/error'
                element={<PrivateRoute><ErrorPage /></PrivateRoute>}
              />
              <Route path='/success'
                element={<PrivateRoute><SuccessPage /></PrivateRoute>}
              />
              <Route path='/search-result'
                element={<SearchResult />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </RecoilRoot>
  );
}

export default App;
