import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import destination from '../utils/destination.json';
import genderData from '../utils/gender.json';
import UseTimeFormate from '../utils/UseTimeFormate';


const Confirmation = () => {
  const [language, setLanguage] = useState('BD');
  const { JapaneesTimeFormatter, timeFormateer } = UseTimeFormate();
  const navigate = useNavigate();
  const intl = useIntl();

  // Destracture all local storage data
  const setClientDataState = useSetRecoilState(clientDataState);
  const { login_time, personal_information_enter_time, from_to_enter_time, name, date_time_enter_time, amount_enter_time, note, note_enter_time, gender, location_from, location_to, reservation_date, reservation_time, amount } = useRecoilValue(clientDataState);
  const [loading, setLoading] = useState(true);

  // find from and to destination and gender by id
  const locationFrom = destination.find(c => c.id === parseInt(location_from));
  const locationTo = destination.find(c => c.id === parseInt(location_to));
  const genderValue = genderData.find(g => g.id === parseInt(gender));

  // All time data
  const timeData = [
    {
      title: 'Login time',
      jpTitle: "ログイン時間",
      time: language === "BD" ? timeFormateer(login_time) : JapaneesTimeFormatter(login_time),
    },
    {
      title: 'Personal information time',
      jpTitle: "個人情報入力時間",
      time: language === "BD" ? timeFormateer(personal_information_enter_time) : JapaneesTimeFormatter(personal_information_enter_time),
    },
    {
      title: 'From to enter time',
      jpTitle: 'から 時刻を入力してください',
      time: language === "BD" ? timeFormateer(from_to_enter_time) : JapaneesTimeFormatter(from_to_enter_time),
    },
    {
      title: 'Date Time enter time',
      jpTitle: "日付 時刻 時刻を入力してください",
      time: language === "BD" ? timeFormateer(date_time_enter_time) : JapaneesTimeFormatter(date_time_enter_time),
    },
    {
      title: "Amount enter time",
      jpTitle: "金額入力時間",
      time: language === "BD" ? timeFormateer(amount_enter_time) : JapaneesTimeFormatter(amount_enter_time),
    },
    {
      title: 'Note enter time',
      jpTitle: "入力時間に注意",
      time: language === "BD" ? timeFormateer(note_enter_time) : JapaneesTimeFormatter(note_enter_time),
    }
  ];


  // All client reservation_date
  const clientData = [
    {
      title: "Name",
      jpTitle: "名前",
      value: name
    },
    {
      title: 'Gender',
      jpTitle: "性別",
      value: language === "BD" ? genderValue?.title : genderValue?.jpTitle
    },
    {
      title: 'From',
      jpTitle: "から",
      value: language === "BD" ? locationFrom?.name : locationFrom?.ja
    },
    {
      title: 'To',
      jpTitle: "に",
      value: language === "BD" ? locationTo?.name : locationTo?.ja
    },
    {
      title: 'Date',
      jpTitle: "日にち",
      value: intl.formatDate(reservation_date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
    },
    {
      title: 'Time',
      jpTitle: "時間",
      value: intl.formatDate(reservation_time, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      })
    },
    {
      title: "Amount(JPY)",
      jpTitle: "金額(円)",
      value: Math.round(amount * 1.47),
    },
  ];

  // Function to create new reservation
  const submitReservation = async () => {
    const reservationData = {
      login_time: login_time,
      personal_information_enter_time: personal_information_enter_time,
      from_to_enter_time: from_to_enter_time,
      date_time_enter_time: date_time_enter_time,
      amount_enter_time: amount_enter_time,
      note_enter_time: note_enter_time,
      name: name,
      gender: gender,
      location_from: location_from,
      location_to: location_to,
      reservation_date: reservation_date,
      reservation_time: reservation_time,
      amount: amount,
      note: note
    }

    await fetch('http://13.214.70.232:4100/api/reservation/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reservationData),
    })
      .then((response) => {
        if (response.status === 400) {
          navigate("/error");
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data) {
          setClientDataState((prev) => {
            const newValue = JSON.parse(JSON.stringify(prev));
            newValue.reservation_id = data.reservation_id;
            return newValue;
          })

          navigate("/success");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        navigate("/error");
      });
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <div className="w-full flex justify-end rounded-md" role="group">
        <button
          type="button"
          onClick={() => setLanguage("BD")}
          className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 "
        >
          BD
        </button>

        <button
          type="button"
          onClick={() => setLanguage("JP")}
          className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 "
        >
          JP
        </button>
      </div>

      <div className="mb-16">
        {
          timeData.map((data, i) => (
            <p key={i} className="text-base">
              <span className="font-medium">{language === "BD" ? data.title : data.jpTitle}</span> : {data.time}
            </p>
          ))
        }
      </div>

      <div className="mb-16">
        {
          clientData.map((data, i) => (
            <p key={i} className="text-base">
              <span className="font-medium">{language === "BD" ? data.title : data.jpTitle}</span> : {data.value}
            </p>
          ))
        }
      </div>

      <h3 className="text-base font-medium text-left mb-3">{language === "BD" ? "Note:" : "ノート"}</h3>
      <textarea
        id="message"
        rows="16"
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
        value={note}
        disabled
      />
      <div className="w-full flex justify-between mt-8">
        <Button onClick={() => navigate('/enter-note')} title="Back" />
        <Button title="Submit" onClick={submitReservation} />
      </div>
    </>
  );
};

export default Confirmation;