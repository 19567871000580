import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import BodyLayout from '../Helper/BodyLayout';

const HomePage = () => {

  return (
    <div className="App">
      <Header />
      <div className="w-full overflow-auto mx-auto">
        <BodyLayout>
          <Outlet />
        </BodyLayout>
      </div>
    </div>
  );
};

export default HomePage;