import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../Helper/Button';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState } from '../store/atoms';
import { defaultNote } from '../utils/data';
import UseTimeFormate from '../utils/UseTimeFormate';


const EnterNote = () => {
  const setClientDataState = useSetRecoilState(clientDataState);
  const { login_time, personal_information_enter_time, from_to_enter_time, date_time_enter_time, amount_enter_time } = useRecoilValue(clientDataState);
  const [loading, setLoading] = useState(true);
  const { JapaneesTimeFormatter } = UseTimeFormate();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const time = new Date().toUTCString();

    setClientDataState((prev) => {
      const newValue = JSON.parse(JSON.stringify(prev));
      newValue.note_enter_time = time;
      newValue.note = data.note;
      return newValue;
    })

    navigate("/final-confirm");
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [])

  if (loading) return (
    <Loader />
  )

  return (
    <>
      <Input
        value={JapaneesTimeFormatter(login_time)}
        title="Login Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(personal_information_enter_time)}
        title="Personal Information Enter Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(from_to_enter_time)}
        title="From To Enter Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(date_time_enter_time)}
        title="Date Time Enter Time"
        disabled
      />

      <Input
        value={JapaneesTimeFormatter(amount_enter_time)}
        title="Amount Enter Time"
        disabled
      />

      <form>
        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 ">Note</label>
        <textarea
          id="message"
          rows="16"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="Leave a comment..."
          defaultValue={defaultNote}
          {...register("note")}
        />

        <div className="w-full flex justify-between mt-8">
          <Button onClick={() => navigate('/enter-amount')} title="Back" />
          <Button onClick={handleSubmit(onSubmit)} title="Next" />
        </div>
      </form>
    </>
  );
};

export default EnterNote;