import { useIntl } from 'react-intl';

const japaniLocalization = (time) => {
    const localization = time?.split(' ')[0]?.split('/')
    const strDate = `${localization[2]} 年  ${localization[1]} 月 ${localization[0]} 日 ${time?.split(' ')[1]}`;

    return strDate;
}



const UseTimeFormate = () => {
    const intl = useIntl();


    const timeFormateer = (time) => intl.formatDate(time, {
        year: 'numeric',
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
        month: '2-digit',
        day: '2-digit'
    })


    const JapaneesTimeFormatter = (time) => (
        japaniLocalization(intl.formatDate(time, {
            year: 'numeric',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
            timeZone: 'Asia/Tokyo',
            month: '2-digit',
            day: '2-digit'
        })
        ))

    return (
        {
            JapaneesTimeFormatter,
            timeFormateer
        }
    );
};

export default UseTimeFormate;