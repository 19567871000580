import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { clientDataState } from '../store/atoms';
import destination from '../utils/destination.json';


const Header = () => {
  const { name, location_from, location_to, reservation_id } = useRecoilValue(clientDataState);

  const [headerTitle, setHeaderTitle] = useState('');
  const location = useLocation();

  const locationFrom = destination.find(c => c.id === parseInt(location_from));
  const locationTo = destination.find(c => c.id === parseInt(location_to));

  const pathname = location.pathname;

  useEffect(() => {
    switch (pathname) {
      case "/enter-note":
        setHeaderTitle(`${name} : Enter Note`);
        break;
      case "/top":
        setHeaderTitle("Top");
        break;
      case "/personal-info":
        setHeaderTitle("Personal Information");
        break;
      case "/destination-select":
        setHeaderTitle(`${name} : From to Selection`);
        break;
      case "/date-time-select":
        setHeaderTitle(`${locationFrom.name} > ${locationTo.name} : Date Time Selection`);
        break;
      case "/enter-amount":
        setHeaderTitle(`${name} : How much do you pay ?`);
        break;
      case "/final-confirm":
        setHeaderTitle(`${name} : Final Confirmation`);
        break;
      case "/error":
        setHeaderTitle("Error!!");
        break;
      case "/success":
        setHeaderTitle(`${name} : ${reservation_id}`);
        break;
      case "/search-result":
        setHeaderTitle(`${name} : ${reservation_id}`);
        break;
      default:
        setHeaderTitle('Login')
    }
  },
    [name, locationFrom, pathname, setHeaderTitle, locationTo, reservation_id]
  );

  return (
    <div className="w-full h-20 bg-[#deeaf6] flex items-center justify-center sticky shadow-md ">
      <h1 className="text-3xl font-bold">{headerTitle}</h1>
    </div>
  );
};

export default Header;