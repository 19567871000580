import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Input from '../Helper/Input';
import Loader from '../Helper/Loader';
import { clientDataState, loginState } from '../store/atoms';


const TopPageContainer = () => {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const setClientDataState = useSetRecoilState(clientDataState);
  const loginData = useRecoilValue(loginState);

  const navigate = useNavigate();

  const onSubmit = async ({ search }) => {
    await fetch(`http://13.214.70.232:4100/api/reservation/${search}`, {
      method: 'GET',

    })
      .then((response) => {
        if (response.status === 404) {
          setErrorMessage("Reservation not found with this ID!")
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data) {
          data.isLoggedIn = true
          setClientDataState(data);
          navigate("/search-result");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleMakeReservations = () => {
    navigate("/personal-info");
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    setClientDataState(loginData);
  }, [loginData, setClientDataState]);


  if (loading) return (
    <Loader />
  )

  return (
    <>
      <div>
        <div className="flex justify-center">
          <button
            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center"
            onClick={handleMakeReservations}>
            Make new reservation
          </button>
        </div>



        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="number"
            max="9999999999"
            errors={errors}
            register={register}
            title="Reservation ID"
            name="search" />

          {
            errorMessage &&
            <div
              className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg "
              role="alert">
              Reservation not found with this ID.
            </div>
          }

          <div
            className="my-8 flex justify-end"
          >

            <button
              type="submit"
              className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center">
              Search
            </button>
          </div>
        </form>


      </div>
    </>
  );
};

export default TopPageContainer;